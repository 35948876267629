import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { UserContext } from "../contexts/user";
import { MenuItem } from "@mui/material";

const apps = [
  {
    value: 1,
    label: 'Learn C Programming',
  },
  {
    value: 4,
    label: 'Learn C++',
  },
  {
    value: 15,
    label: 'Learn SQL & Database',
  },
  {
    value: 52,
    label: 'Learn Ethical Hacking',
  },
  {
    value: 2,
    label: 'Learn Java',
  },
  {
    value: 6,
    label: 'Learn Python: Ultimate Guide',
  },
  {
    value: 37,
    label: 'Learn Web Development',
  },
  {
    value: 10,
    label: 'Learn HTML',
  },
  {
    value: 85,
    label: 'Learn Digital Marketing',
  },
  {
    value: 154,
    label: 'Learn Data Science & Analytics',
  },
  {
    value: 68,
    label: 'Learn Cyber Security',
  },
  {
    value: 33,
    label: 'Learn Android App Development',
  },
  {
    value: 66,
    label: 'Learn Software Engg',
  },
  {
    value: 13,
    label: 'Learn CSS',
  },
  {
    value: 28,
    label: 'Computer Network Tutorials',
  },
  {
    value: 35,
    label: 'Learn Game Dev with Unity & C#',
  },
  {
    value: 8,
    label: 'Learn PHP Programming',
  },
  {
    value: 12,
    label: 'Learn R Programming',
  },
  {
    value: 7,
    label: 'Learn C#',
  },
  {
    value: 32,
    label: 'Learn Blockchain Programming',
  },
  {
    value: 99,
    label: 'Learn Flutter with Dart',
  },
  {
    value: 17,
    label: 'Learn Swift',
  },
  {
    value: 36,
    label: 'Learn Cloud Computing',
  },
  {
    value: 38,
    label: 'Learn React',
  },
  {
    value: 39,
    label: 'Learn Cryptography',
  },
  {
    value: 60,
    label: 'Learn IoT',
  },
  {
    value: 65,
    label: 'Learn OOP',
  },
  {
    value: 63,
    label: 'Learn Go Programming',
  },
  {
    value: 64,
    label: 'Learn Angular JS',
  },
  {
    value: 19,
    label: 'Learn jQuery',
  },
  {
    value: 11,
    label: 'Learn Ruby',
  },
  {
    value: 3,
    label: 'Learn JavaScript',
  },
  {
    value: 25,
    label: 'Learn AI & ML with Python',
  },
  {
    value: 69,
    label: 'Learn Kotlin & Android',
  },
  {
    value: 30,
    label: 'Learn Machine Learning',
  },
  {
    value: 33,
    label: 'Learn App Development',
  },
  {
    value: 88,
    label: 'Learn Full Stack Development',
  },
  {
    value: 71,
    label: 'Design UI/UX',
  },
];

export default function AddressForm() {
  const [state, dispatch] = React.useContext(UserContext);
  const [app, setApp] = React.useState(1);
  return (
    <React.Fragment
    >
      <Typography variant="h6" gutterBottom
        sx={{
          color: "black",
          fontWeight: 'bolder',
          fontFamily: "Arial",
          paddingBottom: 3,
        }}>
        Get Pro Access
      </Typography>
      <Grid container spacing={3}
      >
        <Grid item xs={12} >
          <TextField
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="user@mail.com"
            variant="outlined"
            onChange={(e) => {
              dispatch({ email: e.target.value, languageId: state.languageId || app });
              console.log(app, state.app);
            }}
            sx={{ input: { color: 'black' }, "label": { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            id="app"
            name="app"
            label="App"
            fullWidth
            autoComplete="user@mail.com"
            variant="outlined"
            select
            value={app}
            onChange={(e) => {
              setApp(e.target.value);
              dispatch({ email: state.email, languageId: e.target.value });
            }}
            sx={{ input: { color: 'black' }, "label": { color: "black" }, color: "black" }}
          >
             {apps.map((option) => (
            <MenuItem key={option.value} value={option.value}
            sx={{  color: 'black' }}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
